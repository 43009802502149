//判断是否手机，true是

//const hardware = isMobile() === 'WX' ? 'WX' : (isMobile() === 'ZFB' ? 'ZFB':(isMobile() == true ? 'MO' : 'PC') )

const hardware = isMobile()

export function isMobile(): any {
  const userAgentInfo = navigator.userAgent;

  if (userAgentInfo.indexOf('MicroMessenger') > 0) {
    
    if(userAgentInfo.indexOf('WindowsWechat') > 0) {
      return 'QW'
    }else{
      return 'WX'
    }
  }

  if (/AlipayClient/.test(userAgentInfo)) {
    return 'ZFB'
  }

  const mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let mobile_flag = 'PC';
  //根据userAgent判断是否是手机
  for (let v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = "MO";
      break;
    }
  }
  const screen_width = window.screen.width;
  const screen_height = window.screen.height;
  //根据屏幕分辨率判断是否是手机
  if (screen_width < 500 && screen_height < 800) {
    mobile_flag = 'MO';
  }
  return mobile_flag;
}

export default hardware

