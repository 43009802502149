<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style lang="scss">
  @import url('@/assets/styles/box.css');
  * {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: none !important;
  }

  html,
  body,
  #app {
    height: 100%;
    min-width: 1200px;
    color: var(--el-text-color-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Avenir, Helvetica, Arial, sans-serif, "微软雅黑";
  }
  
  /* element 规范 */
  .el-form--inline .el-form-item {
    margin-right: 12px !important;
  }
  
  .is-bordered {
    margin-right: 12px !important;
  }
  
  .el-avatar>img {
    width: 100%;
  }
</style>