import {
  computed,
} from 'vue';
import {
  useStore
} from '@/store'


export const size_to_px = (e : string) => {
  switch (e) {
    case 'large': return 20;
    case 'small': return 12;
    default: return 16;
  }
}

export const removeKeysWithSubstring = (obj : any, substring : string) => {
  return Object.keys(obj)
    .filter(key => !key.includes(substring))
    .reduce((acc:any, key:any) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

export const get_number_format = (e : number, decimal = 0, b = 1, isFen = true) => {
  let r = ''
  e = e / b
  const a = String(e.toFixed(decimal))
  if (isFen) {
    r += parseInt(a).toLocaleString('en-US');
  } else {
    r += parseInt(a)
  }
  r += a.split(".")[1] ? "." + a.split(".")[1] : ''
  return r
}

export const numFormat = (num : any, fen = 3, xiao = 2, qian = "") => {
  if (num === null) return ''
  num = Number(num).toFixed(xiao).toString()
  num = num.split("."); // 分隔小数点
  const arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
  let res : any = [];
  for (let i = 0, len = arr.length; i < len; i++) {
    if (i % fen === 0 && i !== 0) {
      res.push(","); // 添加分隔符
    }
    res.push(arr[i]);
  }
  res.reverse(); // 再次倒序成为正确的顺序
  if (num[1]) { // 如果有小数的话添加小数部分
    res = res.join("").concat("." + num[1]);
  } else {
    res = res.join("");
  }
  if (qian) {
    res = qian + res
  }
  return res;
}

export function get_page_height(e = 0, px = "px") : string {
  const store = useStore();
  const showtag = computed(() => {
    return store.getters.menuTags;
  });
  const page_height = document.body.scrollHeight
  return (page_height - 58 - 29 - (showtag.value ? 30 : 0) - e) + px
}

export function get_table_height(e : any, limit = 10, isFoot = false, px = "px") : string {
  let a = ''
  if (isFoot) {
    limit++
  }
  limit++
  switch (e) {
    case 'large':
      a = 54 * limit + 20 + px
      break;
    case 'default':
      a = 38 * limit + 20 + px
      break;
    case 'small':
      a = 32 * limit + 20 + px
      break;
  }
  return a
}

export function zh_week(e : number) {
  switch (e) {
    case 0: return '周日';
    case 1: return '周一';
    case 2: return '周二';
    case 3: return '周三';
    case 4: return '周四';
    case 5: return '周五';
    case 6: return '周六';
  }
}


function isEmptyObject(obj : object) : boolean {
  return Object.keys(obj).length === 0;
}

export const isNull = (value : any) => {
  return value === '' || value === undefined || value === null || value === '[]' || value === "{}" || isEmptyObject(value) || value.length == 0
}

export function getroundnum(chars : string, len : number) : string {
  const maxPos = chars.length;
  let res = '';
  for (let i = 0; i < len; i++) {
    res += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return res
}

export function zh_size(e : number, t : string) {
  if (t == 'large') { return e + 10 } else { return e }
}

export const revisePath = (path : any) => {
  if (!path) {
    return ''
  }

  if (path[0] == '/') {
    return path
  } else {
    return `/${path}`
  }
}

export const set16ToRgb = (str : any, a = 0.7) => {
  if (str) {
    const reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
    if (!reg.test(str)) {
      return;
    }
    let newStr = (str.toLowerCase()).replace(/\#/g, '') // eslint-disable-line
    const len = newStr.length;
    if (len == 3) {
      let t = ''
      for (let i = 0; i < len; i++) {
        t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1))
      }
      newStr = t
    }
    const arr = []; //将字符串分隔，两个两个的分隔
    for (let i = 0; i < 6; i = i + 2) {
      const s = newStr.slice(i, i + 2)
      arr.push(parseInt("0x" + s))
    }
    return 'rgba(' + arr.join(",") + ',' + a + ')';
  } else {
    return str
  }

}

export function to_money(n : any) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ];
  const head = n < 0 ? '欠' : '';
  n = Math.abs(n);

  let s = '';

  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);

  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}

export const deepTree = (list : any, oid = "order", id = "id") => {
  const newList : any = []
  const map : any = {}

  list.forEach((e : any) => (map[e[id]] = e))

  list.forEach((e : any) => {
    const parent = map[e.parentId]

    if (parent) {
      (parent.children || (parent.children = [])).push(e)
    } else {
      if (!e.parentId || e.parentId == "0") {
        newList.push(e)
      }
    }
  })

  const fn = (list : any) => {
    list.map((e : any) => {
      if (e.children instanceof Array) {
        e.children = orderBy(e.children, oid)
        fn(e.children)
      }
    })
  }

  fn(newList)

  return orderBy(newList, oid)
}

export function orderBy(list : any, key : any) {
  return list.sort((a : any, b : any) => a[key] - b[key])
}