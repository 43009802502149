import { createApp } from 'vue'
import App from './App.vue'

import { bootstrap } from './bootstrap';

//引入element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import 'element-plus/theme-chalk/dark/css-vars.css'

//加载图标
import '@/assets/styles/icon.css';
import '@/assets/styles/appicon.css';

import { isMobile } from "@/utils/checkMobile"

//字体rem
if (isMobile() == 'WX' || isMobile() == true) {
  const setHtmlFontSize = () => {
    const htmlDom = document.getElementsByTagName('html')[0];
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (htmlWidth >= 750) {
      htmlWidth = 750;
    }
    if (htmlWidth <= 220) {
      htmlWidth = 220;
    }
    htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
  };
  window.onresize = setHtmlFontSize;
  setHtmlFontSize();
}

//引入vant
import Vant from 'vant';
import 'vant/lib/index.css'

const app = createApp(App)

app.use(ElementPlus, {
  locale: zhCn,
})
app.use(Vant)


bootstrap({ app }).then(async ({ router }) => {
  router.isReady().then(() => app.mount('#app'))
})
