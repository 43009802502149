import { loadRouter } from '@/router';
import { loadStore } from '@/store';
import { loadRoutes } from '@/router';
import { loadGuards } from '@/router/guards';

export async function bootstrap(options: any) {
  const { app } = options;
  const store = loadStore(app)
  const router = loadRouter(app)
  await loadRoutes({ store })
  await loadGuards(router);
  return { router }
}

