import storage from '@/utils/storage'
const state = () => ({
  list: storage.get('process') || [
    {
      label: '首页',
      value: '/dashboard',
      keepAlive: true,
      active: true,
      name: 'dashboard',
    },
  ],
})

const mutations = {
  ADD_PROCESS(state: any, item: any) {         
    const index = state.list.findIndex(
      (e: any) => e.value.split('?')[0] === item.value.split('?')[0]
    )
    state.list.map((e: any) => {
      e.active = e.value === item.value
    })

    if (item.value === '/') {
      item.label = '首页'
      item.name = 'dashboard'
      item.value = '/dashboard'
      item.keepAlive = false
      item.active = true
    }

    if (index < 0) {
      if (item.label) {
        state.list.push({
          ...item,
          active: true,
        })
      }
    } else {
      state.list[index].active = true
      state.list[index].keepAlive = item.keepAlive || false
      state.list[index].label = item.label
      state.list[index].value = item.value
      state.list[index].name = item.name
    }
    storage.set('process', state.list, 0)
  },

  DEL_PROCESS(state: any, index: any) {
    state.list.splice(index, 1)
    storage.set('process', state.list, 0)
  },

  SET_PROCESS(state: any, list: any) {
    state.list = list
    storage.set('process', list, 0)
  },

  RESET_PROCESS(state: any) {
    state.list = [
      {
        label: '首页',
        value: '/dashboard',
        keepAlive: true,
        active: true,
        name: 'dashboard',
      },
    ]
    storage.set('process', state.list, 0)
  },
}

const actions = {}

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
}
