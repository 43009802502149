import { createStore } from 'vuex'
import getters from './getters'

const files = require.context('./modules', false, /\.ts$/)

const modules: { [key: string]: any } = {}
files.keys().forEach((key: string) => {
  modules[key.replace(/(\.\/|\.ts)/g, '')] = files(key).default
})
Object.keys(modules).forEach((key) => {
  modules[key].namespaced = true
})

export const store = createStore({
  modules,
  getters,
  strict: true,
})

export function loadStore(app:any) {
  app.use(store)
  return store;
}

export function useStore() {
  return store;
}

export default store;
