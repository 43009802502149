import { createRouter, createWebHistory } from 'vue-router'
import routes from "./modules/pages";
import views from './modules/views';
import phone from './modules/phone';
import { toRaw } from 'vue'
import hardware from "@/utils/checkMobile"
import {
  set16ToRgb
} from '@/utils'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  strict: true,
})

// 挂载路由
export function loadRouter(app: any) {
  app.use(router);
  router.beforeEach((to: any, form: any, next: any) => {
    if (to.meta) {
      if (to.path == '/map/zone/user') {
        document.title = '用户信息'
      } else if (to.path == '/map/zone') {
        document.title = '分区信息'
      } else {
        document.title = to.meta.title
      }
    }
    next()
  })
  return router;
}

// 加载路由
export async function loadRoutes({ store }: any) {
  //加载颜色
  getComputedStyle(document.documentElement).getPropertyValue(`--el-color-primary`)
  document.documentElement.style.setProperty('--el-color-primary', store.getters.menuUserColor)
  getComputedStyle(document.documentElement).getPropertyValue(`--el-color-primary-light-3`)
  document.documentElement.style.setProperty('--el-color-primary-light-3', set16ToRgb(store.getters.menuUserColor, 0.7))
  getComputedStyle(document.documentElement).getPropertyValue(`--el-color-primary-light-5`)
  document.documentElement.style.setProperty('--el-color-primary-light-5', set16ToRgb(store.getters.menuUserColor, 0.5))
  getComputedStyle(document.documentElement).getPropertyValue(`--el-color-primary-light-7`)
  document.documentElement.style.setProperty('--el-color-primary-light-7', set16ToRgb(store.getters.menuUserColor, 0.3))
  getComputedStyle(document.documentElement).getPropertyValue(`--el-color-primary-light-8`)
  document.documentElement.style.setProperty('--el-color-primary-light-8', set16ToRgb(store.getters.menuUserColor, 0.2))
  getComputedStyle(document.documentElement).getPropertyValue(`--el-color-primary-light-9`)
  document.documentElement.style.setProperty('--el-color-primary-light-9', set16ToRgb(store.getters.menuUserColor, 0.1))
  getComputedStyle(document.documentElement).getPropertyValue(`--el-color-primary-dark-2`)
  document.documentElement.style.setProperty('--el-color-primary-dark-2', set16ToRgb(store.getters.menuUserColor, 1.2))
  
  let viewRoutes = toRaw(store.state.menu.viewRoutes);
  if (!viewRoutes) {
    viewRoutes = await store.dispatch("menu/generateRoutes");
    generateDynamicRoutes(viewRoutes)
  } else {
    generateDynamicRoutes(viewRoutes)
  }
}


export function generateDynamicRoutes(list: any) {
  list = list.map((item: any) => {
    return {
      ...item,
      component: () => import('@/' + item.viewPath + '.vue')
    }
  })
  if(hardware=='PC'){
    views.children = views.children.concat(list);
    router.addRoute(views);
  }else{
    phone.children = phone.children.concat(list);
    router.addRoute(phone);
  }
  
  
  // 在动态路由添加后，在将404添加进入，解决刷新是找不到路由跳转404
  router.addRoute({
    path: '/:pathMatch(.*)',
    redirect: '/'
  })
}


export default router
