import { getToken, setToken, removeToken } from '@/utils/auth'
import storage from '@/utils/storage'

const state = () => ({
  token: getToken() || null,
  userInfo: storage.get('userInfo') || {},
})

const mutations = {
  SET_TOKEN(state: any, token: any) {
    setToken(token)
    state.token = token
  },
  SET_USERINFO(state: any, userInfo: any) {
    storage.set('userInfo', userInfo, 0)
    state.userInfo = userInfo
  },
  CLEAR_USER(state: any) {
    state.userInfo = {}
    storage.remove('userInfo')
  },
  CLEAR_TOKEN(state: any) {
    state.token = null
    removeToken()
  },
}

const actions = {
  logout({ commit }: any) {
    commit('CLEAR_USER')
    commit('CLEAR_TOKEN')
    commit('menu/CLEAR_MENU_GROUP', null, { root: true })
    commit('menu/CLEAR_VIEW_ROUTES', null, { root: true })
    commit('menu/CLEAR_PERMIESSION', null, { root: true })
    commit('process/RESET_PROCESS', null, { root: true })
  },
  timeout({ commit }: any) {
    commit('CLEAR_USER')
    commit('CLEAR_TOKEN')
    commit('menu/CLEAR_MENU_GROUP', null, { root: true })
    commit('menu/CLEAR_PERMIESSION', null, { root: true })
    commit('process/RESET_PROCESS', null, { root: true })
  },
  logins({ commit }: any) {
    commit('process/RESET_PROCESS', null, { root: true })
  },
}

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
}
