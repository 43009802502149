import { variables } from '@/config/theme.config'
import storage from '@/utils/storage'
const state = () => ({
  theme: storage.get('theme') || variables.theme,
})

const mutations = {
  CHANGE_SETTING: (state: any, { key, value }: any) => {
    switch (key) {
      case 'theme':
        state.theme = value
        break
      default:
        break
    }
    storage.set(key, value, 0)
  },
}

const actions = {
  changeSetting({ commit }: any, data: any) {
    commit('CHANGE_SETTING', data)
  },
}

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
}
