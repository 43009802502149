const routes = {
  path: '/layoutIndex',
  name: 'Layout',
  component: () => import('@/layout/layoutIndex.vue'),
  children: [
    {
      name: 'dashboard',
      path: '/dashboard',
      component: () => import('@/views/pc/dashboard/dashboardIndex.vue'),
      meta: {
        title: '首页',
        show: true,
        keepAlive: 1,
        icon: 'desktop',
        type: 1,
      },
    }
  ],
}

export default routes;