const colors = {
  primary: '#1890ff',
  success: '#13ce66',
  warning: '#ffba00',
  danger: '#ff4949',
  info: '#5d5d5d',
}

export const variables = {
  theme: colors.danger,
}

export const systems = {
  project: 'adminface',
  title:'项目管理',
  apiurl: 'https://api.lanzhoulan.cn/',
  nullavatar: 'https://elysium.oss-cn-beijing.aliyuncs.com/comment/noavatar.png',
  menuWidth:176,
}