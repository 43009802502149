import { toRaw } from 'vue'
export default {
  // 用户token
  token: (state:any) => state.user.token,
  // 用户信息
  userInfo: (state:any) => state.user.userInfo,
  // 菜单列表
  menuGroup: (state:any) => toRaw(state.menu.menuGroup),
  // 路由列表
  viewRoutes: (state:any) => toRaw(state.menu.viewRoutes),
  // 菜单是否展开
  menuCollapse: (state:any) => state.menu.collapse,
  // 标签是否展开
  menuTags: (state:any) => state.menu.showtag,
  // 用户主题色
  menuUserColor: (state:any) => state.menu.usercolor,
  // 用户字体大小
  menuUserSize: (state:any) => state.menu.usersize,
  // 窗口列表
  processList: (state:any) => state.process.list,
}
