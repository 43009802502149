import Cookies from 'js-cookie'

const TokenKey = 'Authorization_1'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token: string) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function checkToken() {
  if (Cookies.get(TokenKey)) {
    return true;
  } else {
    return false;
  }
}