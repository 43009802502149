import { isNull } from '@/utils'
const storage = {
  /**
   * 保存数据到 Storage , 将数据转换为 JSON 字符串
   * @param {*} key 键
   * @param {*} value 值
   * @param {*} expired 到期时间
   */
  set(key: string, value: any, expired: number) {
    if (!key) {
      throw 'storage 执行 set 方法，需要参数 key。'
    }
    if (isNull(value)) {
      throw 'storage 执行 set 方法，需要参数 value。'
    }
    const source = {
      key,
      value,
    }
    const now = Date.now()
    if (expired) {
      source.value = JSON.stringify({
        data: value,
        expired: now + 1000 * 60 * expired,
      })
    } else {
      source.value = JSON.stringify({
        data: value,
        expired: 0,
      })
    }
    localStorage.setItem(source.key, source.value)
  },
  /**
   * 获取 Storage 中存储的数据，为空或者过期返回 null
   * @param {*} key 键
   */
  get(key: string) {
    if (!key) {
      throw 'storage 执行 get 方法，需要参数 key。'
    }
    let values = {
      data: '',
      expired: 0,
    }
    const now = Date.now()
    const value = localStorage.getItem(key)
    values = value ? JSON.parse(value) : ''
    if (!values) {
      return null
    } else if (now >= values.expired && values.expired !== 0) {
      this.remove(key)
      return null
    } else {
      return values.data
    }

  },
  /**
   * 删除 Storage 中存储的数据
   * @param {*} key
   */
  remove(key: string) {
    localStorage.removeItem(key)
  },
  /**
   * 清除 Storage 中全部数据
   */
  clear() {
    localStorage.clear()
  },
}

export default storage
