import { RouteRecordRaw } from 'vue-router'
import { useStore } from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    meta: { title: useStore().state.system.title },
    component: () => import('@/views/userLogin.vue')
  },
  {
    path: '/page/web',
    name: 'page_web',
    meta: { title: "页面编辑器" },
    component: () => import('@/views/web/webPage.vue')
  }
]


export default routes
