// import { getPermMenu } from '@/api/user'
// import { deepTree, revisePath } from '@/utils'
// import { ElMessage } from 'element-plus'
import { deepTree, revisePath } from '@/utils'
import storage from '@/utils/storage'
import hardware from "@/utils/checkMobile"
const state = () => ({
  // 菜单列表
  menuGroup: storage.get('menuGroup') || [],
  // 路由列表
  viewRoutes: storage.get('viewRoutes') || [],
  //菜单折叠状态
  collapse: storage.get('collapse') === null ? true : (storage.get('collapse')=="1"?true:false),
  //是否使用标签
  showtag: storage.get('showtag') === null ? true : storage.get('showtag'),
  //用户主题色
  usercolor: storage.get('usercolor') || '#3975c6',
  //用户字体大小
  usersize: storage.get('usersize') || 'default',
})

const mutations = {
  SET_MENU_GROUP(state : any, list : any) {
    state.menuGroup = list
    storage.set('menuGroup', list, 0)
  },
  SET_VIEW_ROUTES(state : any, list : any) {
    state.viewRoutes = list
    storage.set('viewRoutes', list, 0)
  },
  SET_COLLASPE(state : any, value = false) {
    state.collapse = value
    storage.set('collapse', value ? '1' : '0', 0)
  },
  SET_TAGS(state : any, value = false) {
    state.showtag = value
    storage.set('showtag', value ? '1' : '0', 0)
  },
  SET_USER_COLOR(state : any, value = false) {
    state.usercolor = value
    storage.set('usercolor', value, 0)
  },
  SET_USER_SIZE(state : any, value = false) {
    state.usersize = value
    storage.set('usersize', value, 0)
  },
  CLEAR_MENU_GROUP(state : any) {
    state.menuGroup = []
    storage.remove('menuGroup')
  },
  CLEAR_PERMIESSION(state : any) {
    state.process = []
    storage.remove('process')
  },
  CLEAR_VIEW_ROUTES(state : any) {
    state.viewRoutes = []
    storage.remove('viewRoutes')
  },
}

const actions = {
  async generateRoutes({ commit } : any, result : any) {
    const routes = result
      .map((e : any) => {
        return {
          id: e.id,
          parentId: e.parentId,
          path: revisePath(e.path),
          viewPath: e.viewPath,
          // fix 解决同名节点 导致组件名称相同而产生的页面加载错误
          name: `${e.name}_${e.id}`,
          meta: {
            keepAlive: e.meta.keepAlive === 1,
            title: e.meta.title || e.name,
            type: e.meta.type,
            icon: e.meta.icon,
            show: e.meta.show,
          },
          children: [],
        }
      })

    if (hardware == 'PC') {
      // 格式化菜单
      const menuGroup = deepTree(routes.filter((e : any) => e.meta.show))
      commit('SET_MENU_GROUP', menuGroup)
    }

    const viewRoutes = routes.filter((e : any) => e.meta.type === 1)
    // 设置视图路由
    commit('SET_VIEW_ROUTES', viewRoutes)
    return viewRoutes

  },
}

export default {
  namespaced: true,
  actions: actions,
  state: state,
  mutations: mutations,
}