import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
import { checkToken } from '@/utils/auth';
import { useStore } from '@/store';

NProgress.configure({ showSpinner: false });

const loginIgnore = {
  names: ['404', '401'],      //根据路由名称匹配
  paths: ['/','/page/web'],   //根据路由fullPath匹配
  // 判断路由是否包含在该配置中
  includes(route: any) {
    return this.names.includes(route.name) || this.paths.includes(route.path)
  }
}

export function loadGuards(router: any) {

  router.beforeEach(async (to: any, from: any, next: any) => {
    if (!NProgress.isStarted()) {
      NProgress.start()
    }
    if (checkToken()) {

      if (to.name == 'Login') {
        next()
        NProgress.done();
      } else {

        const hasRoute = router.hasRoute(to.name);

        if (hasRoute) {
          const store = useStore();
          // if (!store.getters.userInfo) {
          //   await store.dispatch('user/queryUserInfo');
          // }
          if(to.meta){
            store.commit('process/ADD_PROCESS', {
              keepAlive: to.meta.keepAlive,
              label: to.meta.title,
              value: to.fullPath,
              name: to.name
            })
          }
          next()
        } else {
          next({ ...to, replace: true })
        }
      }
    } else {
      if (!loginIgnore.includes(to)) {
        next({
          path: "/",
          replace: true
        });
        NProgress.done();
      } else {
        next()
      }
    }

  })

  router.afterEach(() => {
    NProgress.done()
  })
}